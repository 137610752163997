import { useEffect, useState } from 'react'
import React from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import MenuContext from './MenuContext';
import AuthContext from './context/AuthProvider';

// import logo from './img/logos/logo-spelltell.png';
// import { Link } from 'react-router-dom';



function SignUp() {
    const mc = useContext(MenuContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {logged, setLogged, token, setToken} = useContext(AuthContext);


    useEffect(()=>{
        if(JSON.parse(localStorage.getItem("logged"))){
            navigate("/practice");
        };
    }, [])


    const msgRegisteringUser = (name, email) => () => (
        //this is fired before user is created; it is followed with success/error message
        <div className='notiBox'>
        Welcome <strong style={{ color: "rgb(181, 1, 157)", fontWeight: "600" }} >{name} </strong> 👋 <br />
        We are creating an account for you. <br/>
        Check <strong style={{ color: "rgb(181, 1, 157)", fontWeight: "600" }} >{email} </strong> . <br />
    </div>
    );
    const msgCreated = (name, email) => () => (
        //fires when user is created
        <div className='notiBox'>
        Account created!  <br />
    </div>
    );

    const msgError = (error) => () => (
        <div className='notiBox'>
        <strong style={{ color: "#d80062", fontWeight: "600" }} >{error} </strong>  <br />
    </div>
    );

    const notifyCreating = (name, email) => (toast(msgRegisteringUser(name, email), {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    }));

    const notifyCreated = (name, email) => (toast.success(msgCreated(name, email), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        //Redirect user that has been registered to practice page
        onClose: (error) => {setLoading(false); navigate("/practice")}
    }));

    const notifyError = (error) => (toast.error(msgError(error), {
        position: "top-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {setLoading(false)}
    }));

    //setValue is used to toggle isTerms(read Terms) when users clicks text next to isTerm checkbox
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm(
        {
            defaultValues: {
                isTerms: false
            }
        }
    );

    const onSubmit = async (data) => {
        notifyCreating(data.name, data.email);
        setLoading(true);
        console.log("api request sent:", data);
        regUser(data);
    };

    //console.log("watch",watch("isTerms"));
    const regUser = async (data) => {
        axios.post('https://api.spelltell.com/api/register', data)
            .then(function (response) {
                console.log(response);
                localStorage.setItem("logged", true);
                localStorage.setItem("token", response.data.access_token);
                setLogged(true);
                notifyCreated();
            })
            .catch(function (error) {
                console.log(error);
                notifyError(error.response.data.message);
            });
    }

    return (
        <>
            <ToastContainer />

            <div className='main' onClick={() => { mc.setIsOpen(false) }}>
                <div className="container split">
                    <div className="page column">
                    {loading&&<h2>Creating you account ... 🥳</h2>}
                        {!loading&&<h2>Create an account 🥳</h2>}
                        {/* "handleSubmit" validates inputs before invoking "onSubmit" */}
                        {!loading&&<form className='form' onSubmit={handleSubmit(onSubmit)}>
                            {/* register your input into the hook by invoking the "register" function */}

                            <div className='fieldGroup'>
                                <label htmlFor="firstName">First Name</label>
                                <input className='field' placeholder="Mark" {...register("name", {
                                    required: true,
                                    minLength: {
                                        value: 2,
                                        message: "Minimum lenght is 3 characters"
                                    }
                                })} />
                                <span className='formInstruction'>
                                    {errors.firstName?.message}</span>
                            </div>

                            <div className='fieldGroup'>
                                <label htmlFor="lastName">Last Name</label>
                                <input className='field' placeholder="James" {...register("lastName", {
                                    required: true,
                                    minLength: {
                                        value: 2,
                                        message: "Minimum lenght is 3 characters"
                                    }
                                })} />
                                <span className='formInstruction'>
                                    {errors.lastName?.message}</span>
                            </div>



                            <div className='fieldGroup'>
                                <label htmlFor="email">Email</label>
                                <input className='field' placeholder="mark@gmail.com" {...register("email", {
                                    required: true,
                                    minLength: {
                                        value: 2,
                                        message: "Minimum lenght is 3 characters",
                                    },
                                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                })} />
                                {errors.email && <span className='formInstruction'>
                                    * Email is required</span>}
                            </div>

                            <div className='fieldGroup'>
                                <label htmlFor="password">Password</label>
                                <input type="password" className='field' placeholder="123" {...register("password", {
                                    required: true,
                                    minLength: {
                                        value: 8,
                                        message: "Password must contain characters",
                                    }
                                    // , pattern: /^(?=.*[a-z])(?=.*[1-9]).{8,}$/,
                                })} />
                                {errors.password && <span className='formInstruction'>
                                    * Password must be at least <u>8 characters</u> in length,
                                    {/* and have at least <u>1 number</u> */}
                                    .</span>}
                            </div>

                            <div className='checkBox' style={{ display: "flex" }}>
                                <input
                                    type="checkbox"
                                    checked={watch("isTerms")}
                                    {...register("isTerms", { required: true })}
                                />

                                <label htmlFor="isTerms" onClick={() => { setValue("isTerms", !watch("isTerms")) }}>By continuing you agree to SpellTell’s Terms of Service and Privacy Policy?</label>

                            </div>
                            {errors.isTerms && <span className='formInstruction'>
                                * You must agree to the Terms & Privacy Policy</span>}
                            <input type="submit" />
                        </form>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp