import React from 'react'
import { useContext } from 'react';
import MenuContext from '../MenuContext';
import { Link } from 'react-router-dom';
import bank from '../img/stats/bank.png'
import box1 from '../img/stats/box1.png'
import box2 from '../img/stats/box2.png'
import box3 from '../img/stats/box3.png'
import box4 from '../img/stats/box4.png'

function Stats() {
    const mc = useContext(MenuContext);
    const emailForm = '<iframe width="540" height="850" src="https://d48d886b.sibforms.com/serve/MUIEAFpBY2HJzLc37JF2DH9jeB3EmPAIbUCAFq1VhRTjwM8L6sSw_vsEpno17kLBN4Jv2jf9SgdOwvhFMPGVSBskTe3Bt2N2S0UfC9Js9WHFb78qowVG26WXEscXkavOKpeSXmYnHq1JgvJ7ICKi-OaVmC3ZLYzbFeKdLo5oy0GWSy57KZa8wOJ9ej6QNuyr9c-c2RmQ4afaY3ta" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>';
    return (
        <div className='main' onClick={() => { mc.setIsOpen(false) }}>

            <div className="container split">
                <div className="page column">

                    <Link className="statsBoxLink" to="/practice">
                        <div className="statsBox  statsBox_first panelBackLink flex">
                            <div className="basis100"> <span className='bold textWhite'>← Back to Practicing </span> </div>
                        </div>
                    </Link>

                    <Link className="statsBoxLink" to="/stats">
                        <div className="statsBox flex">
                            <div className="basis25"><img className="img-resp" src={bank} /></div>
                            <div className="basis75 bankStatsRight"> <div className="counter">302434</div> <div className="bankWords">3024</div> <div className="label">words</div></div>
                        </div>
                    </Link>


                    <div className="statsBox flex">
                        <div className="basis100 bold">
                            <span className='textPink'> List All Learned Words (</span> <span className='textTeal'>1024</span>  <span className='textPink'> )</span>
                        </div>
                    </div>


                    <div className="statsBox flex">
                        <div className="basis100 bold">
                            <span className='textPink'> List All Remaining Words (</span> <span className=''>20024</span>  <span className='textPink'> )</span>
                        </div>
                    </div>

                    <div className="statsBox flex">
                        <div className="basis100 bold">
                            <span className='textPink'> List Category Stats (</span> <span className=''>5 Categories</span>  <span className='textPink'> )</span>
                        </div>
                    </div>

                    <div className="statsBox flex">
                        <div className="basis100 bold">
                            <span className='textPink'> List My Current Active Words (</span> <span className=''> 5 Boxes</span>  <span className='textPink'> )</span>
                        </div>
                    </div>



                    <div className="statsBox box1 flex center">
                        <div className="basis50">   <span className='textPink bold'> Box 1</span> <img className="img-resp box" src={box1} /> 111 words</div>
                        <div className="basis50">   <span className='textPink bold'> Box 2</span> <img className="img-resp box" src={box2} /> 111 words</div>
                    </div>

                    <div className="statsBox flex center statsBox_last">
                        <div className="basis50">   <span className='textPink bold'> Box 3</span> <img className="img-resp box" src={box3} /> 111 words</div>
                        <div className="basis50">   <span className='textPink bold'> Box 4</span> <img className="img-resp box" src={box4} /> 111 words </div>
                    </div>







                </div>
            </div>

        </div>
    )
}

export default Stats