const Data = [
    { id: 1, word: "first", pronunciation: "first", progress: [0,0] },
    { id: 2, word: "second", pronunciation: "second", progress: [0,0] },
    { id: 3, word: "third", pronunciation: "third", progress: [0,0] }
  ];
  export default Data;







