import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { useContext, useRef, useEffect } from 'react';
import MenuContext from './MenuContext';
import { useNavigate } from "react-router-dom";
import AuthContext from './context/AuthProvider';

// import logo from './img/logos/logo-spelltell.png';
// import { Link } from 'react-router-dom';

function SignIn() {
    const mc = useContext(MenuContext);
    const success = useRef(false);
    const navigate = useNavigate();
    const {logged, setLogged, token, setToken, setFname} = useContext(AuthContext);


    //redirect user to practice page if they are logged in
    useEffect(()=>{
        if(JSON.parse(localStorage.getItem("logged"))){
            navigate("/practice");
        };
    }, [])

    const notify = (name) => (toast(Msg(name), {
        position: "top-center",
        autoClose: 1200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    }));
    const Msg = (name) => () => (
        <div className='notiBox'>
            {success.current ? <> Welcome back, <strong style={{ color: "rgb(181, 1, 157)", fontWeight: "600" }} >{name}  </strong>! 👋 <br /> </> : <>😔 Invalid username or password. Please try again.</>}

        </div>
    )



    //setValue is used to toggle isRemember(read Terms) when users clicks text next to isTerm checkbox
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm(
        {
            defaultValues: {
                isRemember: false
            }
        }
    );

    const onSubmit = async (data) => {
        console.log("api request sent:", data);
        logUser(data);
    };


    //console.log("watch",watch("isRemember"));
    const logUser = async (data) => {
        const bodyParameters = data;
        axios.post(
            'https://api.spelltell.com/api/login',
            bodyParameters
        ).then(
            res => {
                console.log("radi", res)
                success.current = true;
                // currently I'm returning
                setToken(res.data.access_token);
                notify(res.data.user.name);
                localStorage.setItem("logged", true);
                localStorage.setItem("token", res.data.access_token);
                setLogged(true);
                setFname(res.data.user.name);
                setTimeout(() => { navigate("/practice") }, 1800
                )
            }
        ).catch(res => {
            console.log("error->", res);
            success.current = false;
            notify("nja");
        });
    }

    return (
        <>
            <ToastContainer />
            <div className='main' onClick={() => { mc.setIsOpen(false) }}>
                <div className="container split">
                    <div className="page column">
                        {/* {auth} */}
                        <h2>Welcome Back</h2>
                        {/* "handleSubmit" validates inputs before invoking "onSubmit" */}

                        <form className='form' onSubmit={handleSubmit(onSubmit)}>
                            {/* register your input into the hook by invoking the "register" function */}

                            <div className='fieldGroup'>
                                <label htmlFor="email">Email</label>
                                <input className='field' placeholder="mark@gmail.com" {...register("email", {
                                    required: true,

                                })} />
                                {errors.email && <span className='formInstruction'>
                                    * Email is required</span>}
                            </div>

                            <div className='fieldGroup'>
                                <label htmlFor="password">Password</label>
                                <input type="password" className='field' placeholder="123" {...register("password", {
                                    required: true,
                                })} />
                                {errors.password && <span className='formInstruction'>
                                    * Password must be at least <u>8 characters</u> in length, and have at least <u>1 number</u>.</span>}

                            </div>

                            {/* <div className='checkBox' style={{ display: "flex" }}>
                                <input
                                    type="checkbox"
                                    checked={watch("isRemember")}
                                    {...register("isRemember")}
                                />
                                <label htmlFor="isRemember" onClick={() => { setValue("isRemember", !watch("isRemember")) }}>Remember me so I don't have to sign in next time.</label>
                            </div> */}

                            {errors.isRemember && <span className='formInstruction'>
                                * You must agree to the Terms & Privacy Policy</span>}
                            <input type="submit" />
                        </form>


                    </div>
                </div>
            </div>
        </>
    )
}

export default SignIn