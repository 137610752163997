import React from 'react'
import { useContext } from 'react';
import MenuContext from '../MenuContext';
import SpellTellPractice from "../Box/SpellTellPractice";
import axios from 'axios';
import { get } from 'react-hook-form';
import AuthContext from '../context/AuthProvider';


function Practice() {
    const mc = useContext(MenuContext);
    const { logged, setLogged, token, setToken, setFname } = useContext(AuthContext);




    return (
        <div className='main' onClick={() => { mc.setIsOpen(false) }}>



            <SpellTellPractice></SpellTellPractice>


            <div className="container split">
                <div className="page column">
                </div>
            </div>

        </div>
    )
}

export default Practice