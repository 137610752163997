//import { useState, useReducer, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./Style.css";
import SharedLayout from "./SharedLayout";
import Home from "./Home";
import About from "./About";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import { MenuPovider } from "./MenuContext";
import ButWhy from "./ButWhy";
import Practice from "./pages/Practice";
import Stats from "./pages/Stats";
import ProfileSettings from "./pages/ProfileSettings";
import Update from "./pages/Update";
import ChangePass from "./pages/ChangePass";
import { AuthProvider } from "./context/AuthProvider";


function App() {
  return <div>
  <AuthProvider>
  <MenuPovider>
   <BrowserRouter>
    <Routes>
      <Route path="/" element={<SharedLayout />}>
        <Route index element={<Home />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<About />} />
        <Route path="/but-why" element={<ButWhy />} />

        <Route path="/practice" element={<Practice />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/profile" element={<ProfileSettings />} />
        <Route path="/update" element={<Update />} />
        <Route path="/changepass" element={<ChangePass />} />
        <Route path="/*" element={<h1>404</h1>} />
      </Route>
    </Routes>
  </BrowserRouter>
   </MenuPovider>
  </AuthProvider>
  </div>;
}

export default App;
