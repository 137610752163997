import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { useContext } from 'react';
import MenuContext from '../MenuContext';
// import logo from './img/logos/logo-spelltell.png';
// import { Link } from 'react-router-dom';

function ChangePass() {
    const mc = useContext(MenuContext);
    const Msg = (name, email) => () => (
        <div className='notiBox'>
            We are updating <strong style={{ color: "rgb(181, 1, 157)", fontWeight: "600" }} >{name}'s account' </strong> 🕵  <br />
            You will get an email({email}) notification. <br />
        </div>
    )

    const notify = (name, email) => (toast(Msg(name, email), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    }));

    //setValue is used to toggle isTerms(read Terms) when users clicks text next to isTerm checkbox
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm( );

    const onSubmit = async (data) => {
        notify(data.firstName, data.email);
       // console.log("api request sent:",data);
        regUser(data);
    };


    //console.log("watch",watch("isTerms"));
    const regUser = async (data) => {
        const response = await fetch(`http://127.0.0.1:8000/api/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        // {access_token: '2|OX2P7jALBxBWcvc9KBtpS2Ri86vFzt3X1dTrib4a', token_type: 'Bearer'}
        const resp = await response.json();
        console.log(resp);
        console.log("Login user:",data)
    }

    return (
        <>
            <ToastContainer />
            <div className='main' onClick={() => { mc.setIsOpen(false) }}>
                <div className="container split">
                    <div className="page column">
                        <h2>Change pass</h2>

                        {/* "handleSubmit" validates inputs before invoking "onSubmit" */}

                        <form className='form' onSubmit={handleSubmit(onSubmit)}>
                            {/* register your input into the hook by invoking the "register" function */}

                            <div className='fieldGroup'>
                                <label htmlFor="oldPassword">Old Password</label>
                                <input type="password" className='field' placeholder="123" {...register("oldPassword")} />
                                {errors.oldPassword && <span className='formInstruction'>
                                    * Please type your old password</span>}
                            </div>

                            <div className='fieldGroup'>
                                <label htmlFor="newPassword">New Password</label>
                                <input type="password" className='field' placeholder="123" {...register("newPassword", {
                                    required: true,
                                    minLength: {
                                        value: 8,
                                        message: "Password must contain characters",
                                    },
                                    pattern: /^(?=.*[a-z])(?=.*[1-9]).{8,}$/,
                                })} />
                                {errors.password && <span className='formInstruction'>
                                    * Password must be at least <u>8 characters</u> in length, and have at least <u>1 number</u>.</span>}
                            </div>


                            <div className='fieldGroup'>
                                <label htmlFor="repeatNewPassword">Rpeat New Password</label>
                                <input type="password" className='field' placeholder="123" {...register("repeatNewPassword")} />
                                {errors.password && <span className='formInstruction'>
                                    * Password must be at least <u>8 characters</u> in length, and have at least <u>1 number</u>.</span>}
                            </div>


                            <button type="submit">Change pass</button>
                        </form>


                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePass