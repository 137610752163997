import { useEffect, useState } from 'react';
import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../img/logos/logo-spelltell.png'
import { useContext } from 'react';
import MenuContext from '../MenuContext';
import AuthContext from '../context/AuthProvider';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';



function Menu() {
    //Version 2; make it so that side menu width resized with window resize
    // const [padding, setPadding]=useState(window.innerWidth*0.5);
    // isOpen is state of the slide-menu; if it's true menu is open;
    const mc = useContext(MenuContext);
    const navigate = useNavigate();



    useEffect(() => {
        console.log("changed");
        mc.isOpen ? openSlideMenu() : closeSlideMenu();
    }, [mc.isOpen])
    const { logged, setLogged, setToken, fname} = useContext(AuthContext);

    //check if user has cookie 'looged' & 'token' and if they do update the sates token & loged. State logged control if "sign up" or "sign in" will show in the menu
    useEffect(() => {
        if (localStorage.getItem("token") === "true") {
            setToken(JSON.parse(localStorage.getItem("token")))
        };
        if (JSON.parse(localStorage.getItem("logged"))) {
            setLogged(true)
        };
    }, [])

    function openSlideMenu() {
        let padding = (window.innerWidth * 0.5);
        if (window.innerWidth > 1200) {
            padding = window.innerWidth * 0.5 - 320;
        } else {
            padding = window.innerWidth * 0.8;
        }
        document.getElementById('slide-menu').style.width = padding + "px";
        document.getElementById('slide-menu').style.paddingLeft = `${padding - 240}` + "px";
        //make SpellTell box element move to right when menu opens
        // ERROR: -> button does not exist on all pages;
        //document.getElementById('spelltellBox').style.left = '170px';
    }

    function closeSlideMenu() {
        document.getElementById('slide-menu').style.width = '0';
        document.getElementById('slide-menu').style.paddingLeft = '0px';
        //document.getElementById('spelltellBox').style.left = '0px';
    }
    const goodbye = (name) => () => (
        <div className='notiBox'>
            Good bye, <strong style={{ color: "rgb(181, 1, 157)", fontWeight: "600" }} >{name} 👋!  </strong>
        </div>
    )
    const notify = (name) => (toast(goodbye(name), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    }));

    return (
        <>            <ToastContainer />


            <div className="navi-logo">
                <Link to="/"><img className="img-resp" src={logo} /></Link>
            </div>


            <div className="navi-container" onClick={() => { if (mc.isOpen) mc.setIsOpen(false) }}>
                <div className="container">
                    <nav className="navi" >
                        <span className="open-slide">
                            <Link to="#" onClick={() => { mc.setIsOpen(true) }}>
                                <svg width="30" height="20">
                                    <path d="M0,2 30, 2" stroke="rgb(181, 1, 157)" strokeWidth="2.5"></path>
                                    <path d="M0,10 30, 10" stroke="rgb(181, 1, 157)" strokeWidth="2.5"></path>
                                    <path d="M0, 17.5 30, 17.5" stroke="rgb(181, 1, 157)" strokeWidth="2.5"></path>
                                </svg>
                            </Link>
                        </span>

                        <ul className="navi-nav" >

                            {logged ? <>
                                <li className="logout" onClick={() => {
                                    setLogged(false);
                                    console.log("setLogged to false")
                                    setToken(null);
                                    localStorage.setItem("logged", false);
                                    localStorage.setItem("token", null);
                                    console.log('logging out');
                                    navigate("/");
                                    notify(fname);
                                }}>
                                    Log Out
                                </li>
                                <li><Link className='signup' to="/profile">🧑 Profile</Link></li>
                            </> : <>  <li><Link className="login" to="sign-in">Log In</Link></li>
                                <li><Link className="signup" to="sign-up">Sign Up</Link></li></>}

                        </ul>
                    </nav>
                </div>
            </div>
            <div id="slide-menu" className="slide-nav">
                <Link id="close-button" to="#" className="btn-close" onClick={() => { mc.setIsOpen(false) }}>

                    <svg width="20" height="20">
                        <path d="M0,0 20, 20" stroke="white" strokeWidth="2"></path>
                        <path d="M0,20 20,  0" stroke="white" strokeWidth="2"></path>
                    </svg>
                </Link>
                <Link to="/">Home</Link>
                <Link to="but-why">Why it's Free 😲!?</Link>
                <Link to="about-us">About Us</Link>
                {logged ? <>
                                <span onClick={() => {
                                    setLogged(false);
                                    console.log("setLogged to false")
                                    setToken(null);
                                    localStorage.setItem("logged", false);
                                    localStorage.setItem("token", null);
                                    console.log('logging out');
                                    navigate("/");
                                    notify("Mark");
                                }}>
                                   <a> Log Out</a>
                                </span>
                                <span><Link  to="/profile">🧑 Profile</Link></span>
                            </> : <>  <span><Link to="sign-in">Log In</Link></span>
                                <span><Link  to="sign-up">Sign Up</Link></span></>}


                <Link to="contact-us">Contact</Link>
            </div>
        </>
    )
}

export default Menu