import React from 'react'
import { useContext } from 'react';
import MenuContext from '../MenuContext';
import { Link } from 'react-router-dom';
import profile from '../img/stats/profile.png'
import box1 from '../img/stats/box1.png'
import box2 from '../img/stats/box2.png'
import box3 from '../img/stats/box3.png'
import box4 from '../img/stats/box4.png'

function ProfileSettings() {
    const mc = useContext(MenuContext);
    const emailForm = '<iframe width="540" height="850" src="https://d48d886b.sibforms.com/serve/MUIEAFpBY2HJzLc37JF2DH9jeB3EmPAIbUCAFq1VhRTjwM8L6sSw_vsEpno17kLBN4Jv2jf9SgdOwvhFMPGVSBskTe3Bt2N2S0UfC9Js9WHFb78qowVG26WXEscXkavOKpeSXmYnHq1JgvJ7ICKi-OaVmC3ZLYzbFeKdLo5oy0GWSy57KZa8wOJ9ej6QNuyr9c-c2RmQ4afaY3ta" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>';
    return (
        <div className='main' onClick={() => { mc.setIsOpen(false) }}>

            <div className="container split">
                <div className="page column">

                    <Link className="statsBoxLink" to="">
                        <div className="statsBox statsBox_first flex">
                            <div className="basis25"><img className="img-resp" src={profile} /></div>
                            <div className="basis75 bankStatsRight center">  <div className="profile center textPink">Profile Settings</div> </div>
                        </div>
                    </Link>


                    <Link className="statsBoxLink" to="/update">
                        <div className="statsBox flex">
                            <div className="basis100"> <span className='bold textPink'> Update Email </span> </div>
                        </div>
                    </Link>

                    <Link className="statsBoxLink" to="/update">
                        <div className="statsBox flex">
                            <div className="basis100"> <span className='bold textPink'> Update Name </span> </div>
                        </div>
                    </Link>

                    <Link className="statsBoxLink" to="/upgrade">
                        <div className="statsBox flex">
                            <div className="basis100"> <span className='bold textPink'> Upgrade (Only $1/mo with yearly plan)  </span> </div>
                        </div>
                    </Link>

                    <Link className="statsBoxLink" to="/stats">
                    <div className="statsBox flex">
                        <div className="basis100"> <span className='bold textPink'> My Word Bank </span> </div>
                    </div>
                    </Link>


                    <div className="statsBox flex">
                        <div className="basis100"> <span className='bold textPink'> Delete Account </span> </div>
                    </div>


                    <Link className="statsBoxLink" to="/contact-us">
                        <div className="statsBox flex">
                            <div className="basis100"> <span className='bold textPink'> Contact Us </span> </div>
                        </div>
                    </Link>




                    {/* <div className="statsBox flex">
                        <div className="basis100"> <span className='bold textPink'> Log Out </span> </div>
                    </div> */}


                    <Link className="statsBoxLink" to="/practice">
                        <div className="statsBox  statsBox_last panelBackLink flex">
                            <div className="basis100"> <span className='bold textWhite'>← Back to Practicing </span> </div>
                        </div>
                    </Link>





                </div>
            </div>

        </div>
    )
}

export default ProfileSettings