import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { useContext } from 'react';
import MenuContext from '../MenuContext';
import { Link } from 'react-router-dom';

// import logo from './img/logos/logo-spelltell.png';
// import { Link } from 'react-router-dom';

function Update() {

    const mc = useContext(MenuContext);
    const Msg = (name, email) => () => (
        <div className='notiBox'>
            We are updating <strong style={{ color: "rgb(181, 1, 157)", fontWeight: "600" }} >{name}'s account' </strong> 🕵  <br />
            You will get an email({email}) notification. <br />
        </div>
    )

    const notify = (name, email) => (toast(Msg(name, email), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    }));

    //setValue is used to toggle isTerms(read Terms) when users clicks text next to isTerm checkbox
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm(
        {
            defaultValues: {
                isTerms: false,
                firstName:"Marko",
                lastName:"Rodic",
                email:"woltez@gmail.com"
            }
        }
    );

    const onSubmit = async (data) => {
        notify(data.firstName, data.email);
       // console.log("api request sent:",data);
        regUser(data);
    };


    //console.log("watch",watch("isTerms"));
    const regUser = async (data) => {
        const response = await fetch(`http://127.0.0.1:8000/api/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        // {access_token: '2|OX2P7jALBxBWcvc9KBtpS2Ri86vFzt3X1dTrib4a', token_type: 'Bearer'}
        const resp = await response.json();
        console.log(resp);
        console.log("Login user:",data)
    }

    return (
        <>
            <ToastContainer />
            <div className='main' onClick={() => { mc.setIsOpen(false) }}>
                <div className="container split">
                    <div className="page column">
                        <h2>Update account details</h2>

                        {/* "handleSubmit" validates inputs before invoking "onSubmit" */}

                        <form className='form' onSubmit={handleSubmit(onSubmit)}>
                            {/* register your input into the hook by invoking the "register" function */}

                            <div className='fieldGroup'>
                                <label htmlFor="firstName">First Name</label>
                                <input className='field' placeholder="Mark" {...register("firstName", {
                                    required: true,
                                    minLength: {
                                        value: 2,
                                        message: "Minimum lenght is 3 characters"
                                    }
                                })} />
                                <span className='formInstruction'>
                                    {errors.firstName?.message}</span>
                            </div>

                            <div className='fieldGroup'>
                                <label htmlFor="lastName">Last Name</label>
                                <input className='field' placeholder="James" {...register("lastName", {
                                    required: true,
                                    minLength: {
                                        value: 2,
                                        message: "Minimum lenght is 3 characters"
                                    }
                                })} />
                                <span className='formInstruction'>
                                    {errors.lastName?.message}</span>
                            </div>



                            <div className='fieldGroup'>
                                <label htmlFor="email">Email</label>
                                <input className='field' placeholder="mark@gmail.com" {...register("email", {
                                    required: true,
                                    minLength: {
                                        value: 2,
                                        message: "Minimum lenght is 3 characters",
                                    },
                                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                })} />
                                {errors.email && <span className='formInstruction'>
                                    * Email is required</span>}
                            </div>


                            <button type="submit">Update</button>
                        </form>


                                <Link to="/stats"><div className="backLinkUpdates center">
                                or go back without update
                                </div></Link>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Update