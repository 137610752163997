import React from 'react'
import { createContext } from 'react'
import { useState } from 'react';

const AuthContext = createContext();

export function AuthProvider({children}) {

    const [logged, setLogged] =useState(false);
    const [token, setToken] =useState([]);
    const [fname, setFname] =useState("");


    return(
        <AuthContext.Provider value={{logged, setLogged, token, setToken, fname, setFname}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext




